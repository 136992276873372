<template>
  <div>
    <gl-title title="酷喜管理"></gl-title>
    <gl-page-header>
      <el-form :model="mFormData" ref="listForm" :inline="true" size="mini">
        <el-form-item prop="userId" label="用户ID">
          <gl-list-search v-model="mFormData.userId" placeholder="用户ID"></gl-list-search>
        </el-form-item>
        <el-form-item prop="userName" label="用户昵称">
          <gl-list-search v-model="mFormData.userName" placeholder="用户昵称"></gl-list-search>
        </el-form-item>
        <el-form-item prop="phone" label="手机号">
          <gl-list-search v-model="mFormData.phone" placeholder="手机号"></gl-list-search>
        </el-form-item>
        <el-form-item>
          <el-button  type="primary" icon="el-icon-search" @click="mReload"> 搜索 </el-button>
          <el-button icon="el-icon-refresh" @click="mResetForm('listForm')"> 重置 </el-button>
        </el-form-item>
      </el-form>
    </gl-page-header>
    <gl-card v-loading="mLoading">
      <el-table :data="mTableData" style="width: 100%">
        <el-table-column type="index" :index="mIndexMethod" label="序号" align="center" min-width="30"></el-table-column>
        <el-table-column prop="userId" label="用户ID" width="150"></el-table-column>
        <el-table-column prop="userName" label="用户昵称"></el-table-column>
        <el-table-column prop="phone" label="手机号"></el-table-column>
        <el-table-column prop="contacts" label="联系人名称"></el-table-column>
        <el-table-column prop="contactsPhone" label="联系人电话" width="95"></el-table-column>
        <el-table-column prop="signDate" label="签约日期" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" width="130px" fixed="right">
          <template slot-scope="scope">
            <el-button v-check="'102031'" type="text" @click="mClickAction('mUpdateVisible', scope.row.userId)"> 编辑 </el-button>
            <el-button type="text" @click="mClickAction('mPreviewVisible', scope.row.userId)"> 查看 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <gl-pagination
        :total="mTotal"
        :current-page="mPageNum"
        @currentChange="mHandleCurrentChange"
        @sizeChange="mHandleSizeChange">
      </gl-pagination>
    </gl-card>
    <preview :visible.sync="mPreviewVisible" :itemId="mItemId"/>
    <update :visible.sync="mUpdateVisible" :itemId="mItemId" @reload="mReload"/>
  </div>
</template>

<script>
import ListMixin from '@/mixins/list.mixin.js'
import {getUserList} from '@/api/backendall/member'
import preview from './preview.vue'
import update from './update.vue'
export default {
  components: {preview,update},
  mixins: [ListMixin],
  data(){
    return{
      userType: 2,  // 固定值：1:会员列表, 2:搭档列表  4:厂家管理列表
    }
  },
  mounted(){
    this.mGetListFun = getUserList
    this.mForm.userType = this.userType
    this.mGetList()
  },
}
</script>

<style>

</style>