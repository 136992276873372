<template>
  <el-dialog 
    title="修改"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="600px"
  >
    <gl-wrap right="30px">
      <el-form :model="formData" ref="form" label-width="150px" size="mini" v-loading="loading">
        <el-form-item prop="userName" label="用户昵称" verify>
          <el-input v-model="formData.userName" placeholder="请输入用户昵称"></el-input>
        </el-form-item>
        <el-form-item prop="contacts" label="联系人" verify>
          <el-input v-model="formData.contacts" placeholder="请输入联系人"></el-input>
        </el-form-item>
        <el-form-item prop="contactsPhone" label="联系电话" verify>
          <el-input v-model="formData.contactsPhone" placeholder="请输入联系电话" :maxlength="11"></el-input>
        </el-form-item>
        <el-form-item prop="region" label="所属区域" verify>
          <el-input v-model="formData.region" placeholder="请输入所属区域(如:北京市)"></el-input>
        </el-form-item>
        <el-form-item prop="wxorqq" label="其他联系(QQ/WX)" verify>
          <el-input v-model="formData.wxorqq" placeholder="请输入其他联系(QQ/WX)"></el-input>
        </el-form-item>
        <el-form-item prop="mailbox" label="邮箱" verify>
          <el-input v-model="formData.mailbox" placeholder="请输入邮箱"></el-input>
        </el-form-item>
        <el-form-item prop="addressDetail" label="联系地址" verify>
          <el-input v-model="formData.addressDetail" placeholder="请输入联系地址"></el-input>
        </el-form-item>
        <el-form-item prop="signDate" label="签约日期" verify>
          <el-date-picker
            v-model="formData.signDate"
            type="date"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>

      </el-form> 
    </gl-wrap>
    <gl-flex>
      <el-button type="primary" @click="onSubmit" >确认</el-button>
      <el-button @click="handleClose" >关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
import {getUserById, updataUser} from '@/api/backendall/member'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: '',
    },
  },
  data(){
    return{
      formData: {},
      loading: false,
    }
  },
  watch: {
    visible: {
      handler: function(newVal){
        newVal && this.itemId && this.getAccount()
      }
    }
  },
  methods: {
    onSubmit(){
      this.$refs.form.validate(async valid => {
        if(!valid) return false
        let params = {
          ...this.formData,
          signDate: new Date(this.formData.signDate).getTime()
        }
        this.loading = true
        let res = await updataUser(params)
        this.loading = false
        this.$message.success('操作成功')
        this.handleClose()
        this.$emit('reload')
      })
    },
    async getAccount(){
      this.loading = true
      const res = await getUserById(this.itemId)
      this.formData = {
        userId: res.data.userId,
        userName: res.data.userName,
        contacts: res.data.contacts,
        contactsPhone: res.data.contactsPhone,
        region: res.data.region,
        wxorqq: res.data.wxorqq,
        mailbox: res.data.mailbox,
        addressDetail: res.data.addressDetail,
        signDate: res.data.signDate,
      }
      this.loading = false
    },
    handleClose(){
      this.$emit('update:visible', false)
      this.formData = {}
      this.$refs.form.resetFields()
    },
  },
}
</script>

<style>

</style>