import request from '@/plugins/request'

// 会员管理 && 搭档酷喜管理 && 厂家管理      api

// 会员管理-会员管理-列表
export function getUserList(data = {}){
  let params = {
    ...data,
    userType: 2,  // 固定值：1:会员列表, 2:搭档列表  4:厂家管理列表
  }
  return request({
    url: `/api/user/info/getUserList`,
    method: 'POST',
    data: data,
  })
}

// 会员管理-会员管理-详情
export function getUserById(userId){
  if(!userId) return new Error('userId is undefined') 
  let params = {
    userId: userId
  }
  return request({
    url: '/api/user/info/getUserById',
    method: 'GET',
    params,
  })
}

//会员管理-会员管理-修改
export function updataUser(data = {}){
  return request({
    url: `/api/user/info/updataUser`,
    method: 'POST',
    data: data,
  })
}

// //会员管理-会员管理-新增
// export function addUser(data = {}){
//   let params = {
//     ...data,
//     signDate: new Date(data.signDate).getTime()
//   }
//   return request({
//     url: `/api/user/info/addUser`,
//     method: 'POST',
//     data: params,
//   })
// }






export default {
  getUserList,
  getUserById,
  updataUser,
}